import React, { useState } from 'react';
import projects from "./assets/projectData.json";
import snackums from "./assets/images/snackums.png";
import glob_blog from "./assets/images/glob-blog.png";
import pettit from "./assets/images/pettit.png";
import Carousel from "react-bootstrap/Carousel";

const Portfolio = () => {

  let images = {
    snackums: snackums,
    glob_blog: glob_blog,
    pettit: pettit,
  };

  const renderProject = (project) => {
  
    return (
      <Carousel.Item key={project.key} className="justify-content-center">
	<img src={images[project.image]} className="w-100"/>
	<Carousel.Caption>
	  <h3 className="text-dark bg-light">{project.description}</h3>
	  <a href={project.repo} className="bg-light">REPO</a>
	  <br></br>
	  <a href={project.deployed} className="bg-light">DEPLOYED</a>
	</Carousel.Caption>
      </Carousel.Item>
    );
  };
  
  return (
    <>
      <h2 className="text-center">Portfolio</h2>
	<Carousel className="d-flex flex-row align-items-center justify-content-center">
	  {projects.map((project) => renderProject(project))}
	</Carousel>
    </>
  );
};

export default Portfolio;

