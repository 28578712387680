import React from 'react';
import ReactDOM from "react-dom";
import portrait from "./assets/images/portrait.jpg";
import "./assets/Raise.css";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faHtml5, faCss3Alt, faNodeJs, faReact} from "@fortawesome/free-brands-svg-icons";
import {faDatabase} from "@fortawesome/free-solid-svg-icons";


const AboutMe =() => {
  return (
    <div className="d-flex flex-column align-items-center container-sm">
      <img
	src={portrait}
	alt="portait image of Cody Wenrich"
	height="300"
	style={{borderRadius: "50%",
		margin: "2rem"}}
      />
      <h2>About Me</h2>
      <h3> Skills</h3>
	  
	<div className="fs-3 text-center d-flex flex-row w-50 justify-content-evenly m-3">
	  <div className="d-flex flex-column Raise container-fluid rounded-end rounded-start-pill ">
	    <FontAwesomeIcon icon={faHtml5} className="fs-1"/>
	    <p className="fs-6">HTML5</p>
	  </div>
	  <div className="d-flex flex-column Raise container-fluid  rounded-2">
	    <FontAwesomeIcon icon={faCss3Alt} className="fs-1"/>
	    <p className="fs-6">CSS3</p>
	  </div>
	  <div className="d-flex flex-column Raise container-fluid  rounded-2">
	    <FontAwesomeIcon icon={faNodeJs} className="fs-1"/>
	    <p className="fs-6">JS</p>
	  </div>
	  <div className="d-flex flex-column Raise container-fluid  rounded-2">
	    <FontAwesomeIcon icon={faReact} className="fs-1"/>
	    <p className="fs-6">React</p>
	  </div>
	  <div className="d-flex flex-column Raise container-fluid  rounded-2">
	    <FontAwesomeIcon icon={faDatabase} className="fs-1"/>
	    <p className="fs-6">SQL</p>
	  </div>
	  <div className="d-flex flex-column Raise container-fluid  rounded-end-circle">
	    <FontAwesomeIcon icon={faDatabase} className="fs-1"/>
	    <p className="fs-6">MongoDB</p>
	  </div>
	</div>
	
	<div className="continer w-75"> 
	  <p>
	    After obtaining a BS in Mechanical Engineering computers and software continued to interest me. By the end of schooling, I was teaching myself python and how to run a Linux machine. This lead me to get some web scraping gigs utilizing both Selenium and Beautiful Soup.
	</p>
	  <p>
	    Starting as an inventory lead, I was quickly promoted and later asked to extend the ERP system with VBScript. I self-taught myself VBS and installed three scripts extending the ERP system functionality. Then finally I scrapped the company's website to help get a feel for what exactly was displaying on it for review.
	</p>
	  <p>
	    I stepped away while receiving my Engineering Intern Certificate and before I started working at Bendix. I found CWRU's code camp and jump into it. Completing it was the culmination of many years' desires to code and make a career out of it. I completed the camp and was hired the 2U as a TA for the same course right afterwards. 
	  </p>
	  <p>
	    As a junior JavaScript developer, based near Cleveland, Ohio prefering remote work. My availability depends on the type of position, for part time 20hrs a week and full time opportunities I can make 40hrs.
	    </p>
      </div>
    </div>
  );
};

export default AboutMe;
