import React from 'react';
//import logo from './logo.svg';
import './App.css';
import Wrapper from './components/Wrapper';

function App() {
  return (
    <Wrapper />
  );
}

export default App;

